<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">{{$t('worker.workerDetail.informationTitle')}}</h4>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div v-if="worker" class="md-layout-item md-small-size-100 md-size-100">
          <p v-text="worker.name + ' ' + worker.last_name"></p>
          <p v-text="worker.rut"></p>
          <p v-text="worker.department_name"></p>
          <p>{{$t('worker.workerDetail.dateOfBirth')}}: <span v-text="worker.birth"></span></p>
          <p>{{$t('worker.workerDetail.weight')}} <span v-text="worker.weight"></span></p>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "worker-info",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  watch: {
    "$route.params.worker": function () {
      this.worker = null;
      this.getWorker();
    },
    "$route.params.tab": function () {
      this.worker = null;
      this.getWorker();
    },
  },
  data() {
    return {
      worker: null,
    };
  },
  methods: {
    deleteWorker(id) {
      if (confirm("Are you sure?")) {
        this.$store
          .dispatch("worker/deleteWorker", id)
          .then((data) => {
            if (data.status == "success") {
              this.filter = "";
              this.getWorkers();
              this.$notify({
                message: "The worker was deleted",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            } else {
              this.$notify({
                message: data.message,
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "danger",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              message: "Something went wrong",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          })
          .then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      }
    },
    getWorker() {
      this.$store
        .dispatch("worker/getWorker", this.$route.params.worker)
        .then((data) => {
          this.worker = data.response;
        });
    },
  },
  mounted() {
    this.getWorker();
  },
};
</script>