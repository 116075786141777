<template>
  <v-expansion-panels focusable v-bind:style="{ 'padding-bottom': '20px' }">
    <v-expansion-panel>
      <v-expansion-panel-header
        v-bind:style="{ 'background-color': '#2DCB7F', color: 'white' }"
        color="primary"
      >
        <h4 class="title">{{$t('worker.workerDetail.addresTitle')}}</h4>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <p></p>
        <div
          v-if="address"
          class="md-layout-item md-small-size-100 md-size-100"
        >
          <p v-text="address.street + ' ' + address.number"></p>
          <p v-text="address.state + ', ' + address.city"></p>
          <p v-text="address.country"></p>
          <p v-text="address.postal_code"></p>
          <p v-text="address.reference"></p>
        </div>
        <div v-else class="md-layout-item md-small-size-100 md-size-100">
          <p>{{$t('worker.workerDetail.addresNoInformation')}}</p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "worker-address",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  watch: {
    "$route.params.worker": function () {
      this.address = null;
      this.getAddress();
    },
  },
  data() {
    return {
      address: null,
    };
  },
  methods: {
    getAddress() {
      this.$store
        .dispatch("worker/getAddress", this.$route.params.worker)
        .then((data) => {
          this.address = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAddress();
  },
};
</script>