<template>
  <md-card v-if="slots">
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">Credential Information</h4>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div v-if="battery" class="md-layout-item md-small-size-100 md-size-33">
          <battery :deviceSlot="battery" />
        </div>
        <div
          v-if="gsmSignal"
          class="md-layout-item md-small-size-100 md-size-33"
        >
          <signal :deviceSlot="gsmSignal" />
        </div>
        <div
          v-if="operator"
          class="md-layout-item md-small-size-100 md-size-33"
        >
          <number :deviceSlot="operator" />
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import Battery from "../../components/Monitoring/Battery";
import Signal from "../../components/Monitoring/Signal";
import Number from "../../components/Monitoring/Number";
import { mapGetters } from "vuex";
export default {
  name: "credential-info",
  components: {
    Battery,
    Signal,
    Number,
  },
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  watch: {
    "$route.params.worker": function () {
      this.slots = [];
      this.battery = null;
      this.gsmSignal = null;
      this.operator = null;
      this.getSlots();
    },
  },
  data() {
    return {
      slots: [],
      battery: null,
      gsmSignal: null,
      operator: null,
    };
  },
  methods: {
    getSlots() {
      this.$store
        .dispatch("worker/getSlots", this.$route.params.worker)
        .then((data) => {
          this.slots = data.slots;
          this.battery = this.slots.find(
            (slot) => slot.parameter_name === "battery"
          );
          this.operator = this.slots.find(
            (slot) => slot.parameter_name === "operator_id"
          );
          this.gsmSignal = this.slots.find(
            (slot) => slot.parameter_name === "gsm_signal"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSlots();
  },
};
</script>