<template>
  <div class="container-fluid">
    <div class="row justify-space-between mr-5">
      <tgl-breadcrumbs :items="breadcrumbs" />
      <worker-actions
        v-if="$store.getters.getPermission('workers_remove')"
      ></worker-actions>
    </div>
    <div class="container-fluid">
      <v-tabs v-model="tab">
        <v-tab @click="updateRoute('profile')">{{
          $t("worker.workerTab.details")
        }}</v-tab>
        <v-tab @click="updateRoute('edit')">{{
          $t("worker.workerTab.edit")
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-2 rounded-md">
        <v-tab-item>
          <worker-profile></worker-profile>
        </v-tab-item>
        <v-tab-item>
          <worker-form></worker-form>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";
import WorkerProfile from "@/pages/WorkerProfile";
import WorkerForm from "@/components/Workers/WorkerForm";
import WorkerActions from "@/components/Workers/Actions";

export default {
  name: "department-view",
  components: {
    TglBreadcrumbs,
    WorkerProfile,
    WorkerForm,
    WorkerActions,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Trabajadores",
          disabled: false,
          to: { name: "workers" },
        },
        {
          label: "Trabajador",
          disabled: true,
          to: { name: "department" },
        },
      ];
    },
  },
  methods: {
    setTab(tabName) {
      switch (tabName) {
        case "profile":
          this.tab = 0;
          break;
        case "edit":
          this.tab = 1;
          break;
        default:
          this.tab = 0;
          break;
      }
    },
    updateRoute(path) {
      this.$router.replace(`/workers/${this.$route.params.worker}/${path}`);
    },
  },
  mounted() {
    this.setTab(this.$route.params.tab);
  },
};
</script>